import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { TitleStrategy, provideRouter } from '@angular/router';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { AppStateService, NgxAimModule, TemplatePageTitleStrategy, appTransferStateCacheFilter, initApp, unauthorizedInterceptor } from 'ngx-aim';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(withHttpTransferCacheOptions({
      includePostRequests: true,
      filter: appTransferStateCacheFilter
    })),
    provideHttpClient(withInterceptors([unauthorizedInterceptor])),
    importProvidersFrom(
      NgxAimModule.forRoot({ apiUrl: environment.apiUrl }),
      ToastModule
    ),
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppStateService],
      multi: true,
    },
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    provideAnimations(),
  ]
};